.account-menu {
  width: 9rem;
  padding: 0.5rem;
  border-radius: 0.375rem;
  text-transform: capitalize;

  li {
    border-radius: 0.375rem;
    p {
      font-size: 0.875rem;
      line-height: 1.3;
      margin-bottom: 0;
    }

    &:hover {
      background-color: #f9fafb;
    }
  }

}

.header__user {
  display: flex;
  align-items: center;
  justify-content: end;
  text-transform: capitalize;

  .navigation__menu {
    padding: 0 0.5rem;
  }    
}

.avatar-logo {
  width: 2rem;
  height: 2rem;
  object-fit: cover;
  border-radius: 50%;
  box-shadow: 0rem 0.0625rem 0.25rem #d9d9d9;
}

.menu-drawer {
  .ant-drawer-header {
    display: none;
  }

  .ant-drawer-body {
    background-color: #1e1f25;
  }
}

@media (max-width: 48rem) {
  .bottom-footer {
  }
}

.hamburger{
  // height: 20px;
}
.hamburger:hover{
  color: rgba(0, 0, 255, 0.726);
}