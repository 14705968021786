.subscribe-modal {
	.ant-modal-body {
		padding-bottom: 12px;
	}

	.ant-modal-footer {
		display: none;
	}

	.d-flex {
		margin-top: 24px;
		justify-content: right;
	}

	.StripeElement {
		background: #e6e6e6;
		padding: 12px;
		border-radius: 8px;
	}

	.error-msg {
		color: red;
		margin: 0.5rem;
	}
}


#rswp-card-button.loading {
	background-color: #e5e7eb;
	cursor: not-allowed;
	pointer-events: none;
  }