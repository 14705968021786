* {
  margin: 0;
  padding: 0;
}
@tailwind base;
@tailwind components;
@tailwind utilities;

// html {
// font-size: calc(16px + (32 - 16) * ((100vw - 100rem) / (3200 - 1600)));
// }

html {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  font-family: sans-serif;
}

body {
  // font-family: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";

  color: #1a1b1f;
  font-family: Montserrat, sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.75rem;
}

.app-layout {
  background-position: top right;
  background-repeat: no-repeat;
  min-height: 100vh;

  main {
    transition: 0.2s all;

    .container {
      max-width: 93.75rem;
      margin: 0 auto;
      padding: 2rem;

      &.dashboard {
        width: 100%;
        margin-top: 4.125rem;
      }
    }
  }
}

.auth-layout {
  min-height: 100vh;
  // background-image: url('../public/images/background.png');
  // background-color: #2569f3;
  // background: rgb(71, 71, 237);
  // background: radial-gradient(
  //   circle,
  //   rgba(71, 71, 237, 0.8603816526610644) 10%,
  //   rgba(52, 116, 238, 1) 11%,
  //   rgba(52, 116, 238, 1) 39%,
  //   rgba(52, 116, 238, 0.9472163865546218) 56%,
  //   rgba(52, 116, 238, 1) 70%,
  //   rgba(52, 116, 238, 1) 82%,
  //   rgba(51, 118, 238, 1) 100%
  // );
  background-repeat: no-repeat;
  background-size: cover;

  & > main {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.header {
  position: fixed;
  z-index: 99;
  width: 100%;
  box-shadow: 0 0.0625rem 0 #0000001a;
  padding: 0rem 2rem;
  height: 4.125rem;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &.dashboard {
    width: calc(100% - 18.75rem);

    .header__logo {
      font-size: 1rem;
      font-weight: 500;
      line-height: 1;
      text-transform: uppercase;
    }
  }

  &__user {
    cursor: pointer;

    p {
      line-height: 1.3;
      font-size: 1rem;
      margin-bottom: 0;
      text-wrap: nowrap;

      span {
        font-size: 0.75rem;
      }
    }
  }
}

.sidebar {
  z-index: 100;
  overflow: auto;
  height: 100%;
  position: fixed;
  left: 0;

  .header__logo > img {
    width: 9rem;
  }

  .ant-layout-sider-children {
    padding: 1.5rem;
    border-right: 1.5px solid #ddd;
  }

  ul {
    padding: 1.875rem 0;
    border: none;

    li {
      border-radius: 0.5rem;
      height: auto !important;
      line-height: 1.5 !important;
      padding: 0.5rem !important;
      font-weight: 500;
      font-size: 0.875rem;

      &:after {
        display: none;
      }

      .anticon {
        font-size: 1.3rem;
        color: #868686;
      }

      &:hover {
        background-color: #f9fafb;
        * {
          color: #1c64f2 !important;
        }
      }
    }
  }

  .ant-menu-item-selected {
    background-color: #f9fafb !important;

    * {
      color: #1c64f2 !important;
    }
  }
}

.dashboard-page {
  position: relative;

  .ant-tabs-nav:before {
    display: none;
  }

  .ant-tabs-nav-list {
    background: #f3f4f6;
    padding: 0.3rem;
    border-radius: 10px;

    .ant-tabs-ink-bar {
      display: none;
    }

    .ant-tabs-tab {
      padding: 0.6rem 1.2rem;
      font-weight: 500;

      .ant-tabs-tab-btn {
        color: #111827;
      }
    }

    .ant-tabs-tab-active {
      background-color: #ffffff;
      border-radius: 0.5rem;
    }
  }
}

.ant-form-item {
  margin-bottom: 1rem;
}

.ant-form-item-label > label {
  color: #1a1b1f;
  margin-bottom: 0.5rem;
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.5rem;
}

.ant-form-item.hide-label {
  label {
    display: none;
  }
}

.ant-tag {
  border-radius: 0.25rem;
  margin-bottom: 0.313rem;
}

.ant-pagination-options .ant-select-selector {
  height: 2rem !important;
}

.ant-pagination-options .ant-select-selection-item {
  line-height: 2rem !important;
}

.ant-image-preview-img {
  background: white;
}

.form-control > input {
  border-radius: 0;
}

.page-container {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.d-flex {
  display: flex !important;
  justify-content: center;
  align-items: center;

  &.d-space {
    justify-content: space-between;
  }

  &.d-end {
    justify-content: end;
  }
}

.top-banner {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 3rem;
  border: none;
  background: #101010;
  border-radius: 0;

  .ant-alert-content {
    color: #ffffff;
    text-align: center;
    font-weight: 500;

    .ant-alert-message {
      color: #ffffff;
      font-size: 0.875rem;
    }
  }

  .ant-alert-close-icon .anticon-close {
    color: #ffffff !important;
  }
}

.highcharts-credits {
  display: none;
}

.btn {
  background-color: #1c64f2;
  color: #ffffff;
  font-weight: 500;
  padding: 0.3125rem 2.5rem;
  font-size: 0.875rem;
  border-radius: 0.375rem;

  &.full {
    width: 100%;
  }

  &:hover {
    color: #ffffff;
    background-color: #1c64f2c9;
  }
}

.ant-card-bordered {
  border-color: #cccccc;
}

.number-input.ant-input-number,
.number-input.ant-input-number-affix-wrapper,.ant-input-number-group-wrapper {
  width: 100%;
}

.ant-input-number-prefix {
  color: #999999;
}

.shadow-text-input {
  background: #f2f2f2 !important;
}

.ant-input,
.ant-input-affix-wrapper,
.ant-select-selector {
  &:focus,
  &:hover {
    border-color: #1c64f2;
  }
}

.ant-input-focused {
  border-color: #1c64f2;
}

.ant-spin-nested-loading {
  width: 100%;

  > div > .ant-spin {
  }
}

.ant-picker {
  width: 100%;
}

.ant-btn {
  &.w-full {
    width: 100%;
  }
}

.ant-statistic-content {
  font-family: Montserrat, sans-serif;

  .ant-statistic-content-prefix {
    margin-right: 1px;
  }

  .ant-statistic-content-suffix {
    margin-left: 1px;
  }
}

.hidden-item {
  display: none;
}

.text-left {
  text-align: left !important;
}

.text-center {
  text-align: center !important;
}

.text-inline {
  text-wrap: nowrap !important;
}

.text-cap {
  text-transform: capitalize;
}

.no-border {
  border: none !important;
}

.m-auto {
  margin: auto;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-1 {
  margin-bottom: 0.75rem !important;
}

.mb-2 {
  margin-bottom: 1rem;
}

.mb-3 {
  margin-bottom: 1.25rem !important;
}

.mb-4 {
  margin-bottom: 1.5rem;
}

.mt-1 {
  margin-top: 0.75rem;
}

.w-full {
  width: 100% !important;
}

.search-input {
  max-width: 24rem;
}

#nprogress .spinner {
  display: none !important;
}

::-webkit-scrollbar {
  width: 0.375rem;
}

/* Track */
::-webkit-scrollbar-track {
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #cccccc;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #999999;
}

.ant-tabs-tab .anticon {
  margin: 0;
}

.not-found-sub-page {
  background: #ffffff;

  .ant-result-title {
    font-size: 2rem;
  }

  .ant-result-subtitle {
    font-size: 1rem;
    margin: 2rem auto 3rem;
  }
}

.profile-view-menu {
  left: -1.2rem;
  top: 0.6rem;
  text-align: center;
  padding: 0.4rem 0.5rem;

  a {
    font-size: 1rem;
    font-weight: bold;
  }
}

.main-page {
  width: 100%;
  display: block;
  min-height: 100vh;
  padding-top: 4.125rem;
  position: relative;
}

.custom-marker {
  width: 13rem;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  h3 {
    margin: 0;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.3rem;
  }

  .title {
    color: #000;
    font-weight: 400;
    font-size: 1rem;
    margin-bottom: 0;
  }

  p {
    margin-bottom: 0;
  }

  .ant-divider {
    margin: 0.375rem 0;
  }

  .budget-text {
    color: #000;
    margin: 0rem;
    font-size: 0.8125rem;
  }
}

.field-label {
  font-weight: 600;
  color: #111827;
  background: #f3f4f6;
  padding: 0.4rem 0.5rem;
  border-radius: 0.25rem;
}

.ant-table-thead > tr > th {
  font-weight: 600;
  color: #111827;
}

.ant-modal {
}

@media screen and (min-width: 25.1rem) and (max-width: 48rem) {
  .app-layout {
    main {
      .container {
        padding: 4rem;
      }
    }

    .admin-layout {
      margin-top: 8.3rem;
    }
  }

  .header {
    padding: 0 1rem;

    &.dashboard {
      width: 100%;
      height: 8.3rem;
      padding: 0 1rem;

      > div {
        width: 100%;
      }

      .header__logo {
        font-size: 1rem;

      }

      .search-wrapper {
        width: 100%;
      }

      .anticon-menu {
        display: flex;
        align-items: center;
        font-size: 1.8rem;
      }
    }

    &__user {
      p {
        font-size: 0.8rem;
      }
    }
  }

  .main-page {
  }

  .page-container {
    height: auto;
  }
}
@media screen and (max-width: 25rem) {
  .app-layout {
    main {
      .container {
        padding: 1rem;
      }
    }

    .admin-layout {
      margin-top: 8.3rem;
    }
  }

  .header {
    padding: 0 1rem;

    &.dashboard {
      width: 100%;
      height: 8.3rem;
      padding: 0 1rem;

      > div {
        width: 100%;
      }

      .header__logo {
        font-size: 0.8rem;

      }

      .search-wrapper {
        width: 100%;
      }

      .anticon-menu {
        display: flex;
        align-items: center;
        font-size: 1.8rem;
      }
    }

    &__user {
      p {
        font-size: 0.8rem;
      }
    }
  }

  .main-page {
  }

  .page-container {
    height: auto;
  }
}
