.properties-page {
  .ant-card{
    overflow-x: auto;
  }
  .property-actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 3rem;

    .ant-input-affix-wrapper {
      width: auto;
    }
  }

  .ant-statistic {
    .ant-statistic-title {
      font-weight: 500;
    }
    
    .ant-statistic-content {
      font-size: 0.875rem;
      display: flex;
    }
  }
}