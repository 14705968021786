.float-right {
	float: right;
}

.value-add-additional-form {
	.ant-form-item {
		margin-bottom: 0.25rem;
	}

	.row-header {
		border-bottom: 1px solid #0000000f;
		margin-bottom: 0.5rem;
	}

	.ant-input-number, .ant-input-number-affix-wrapper {
		width: 100%;
	}
}