.subscription-guard {
	width: 100%;
	position: absolute;
	left: 0;
	top: 0;
	background-color: #c1c1c1e0;
	height: 100vh;
	z-index: 100;
	display: flex;

  .ant-card {
  	text-align: center;
  	margin: auto;
  	width: 32rem;
  }

	.logo {
		width: 9rem;
	}

	h4 {
		margin: 2rem auto;
	}
}