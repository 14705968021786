@import '~antd/lib/style/themes/default.less';
@import '~antd/dist/antd.less';

// Size
@height-base: 38px;
@height-lg: 56px;
@input-height-base: 38px;
@input-height-lg: @height-lg;
@border-radius-base: 5px;
@card-radius: 14px;
@font-size-base: 14px;
@font-size-lg: @font-size-base + 4px;
@pagination-item-size: 32px;
@breadcrumb-font-size: 12px;

// Colors
@heading-color: #101010; // heading text color
@text-color: #101010; // major text color
@text-color-secondary: rgba(0, 0, 0, 0.45); // secondary text color
@primary-color: #1c64f2;
@highlight-color: #9cffc2;
@text-color-secondary: #727272;
@text-info-color: #AEB9D0;


// Typography
@typography-title-font-weight: bold;

// Body
@body-background: #FFFFFF;

// Layout
@layout-body-background: transparent;
@layout-header-height: 66px;
@layout-header-background: #ffffff;
@layout-sider-background: #ffffff;
@layout-trigger-background: #ffffff;
@layout-header-padding: 0 40px;
@layout-trigger-height: 52px;

// Menu
@menu-inline-toplevel-item-height: 50px;
@menu-item-height: 50px;
@menu-item-color: @text-color;
@menu-item-active-bg: #EDEFF4;
@menu-item-active-border-width: 3px;
@menu-item-font-size: 16px;
@menu-highlight-color: @highlight-color;
@menu-icon-size-lg: 20px;

// Tabs
@tabs-ink-bar-color: @highlight-color;
@tabs-highlight-color: @highlight-color;

// Input
@input-border-color: #CCCCCC;
@input-hover-border-color: #3898ec;
@select-single-item-height-lg: @height-lg;
@select-dropdown-height: 40px;
@select-dropdown-line-height: 40px;

// Card
@card-head-color: @heading-color;
@card-head-font-size: @font-size-lg;
@card-head-font-size-sm: @font-size-base;


:root {
  --primary-color: @primary-color;
  --highlight-color: @highlight-color;
  --text-info-color: @text-info-color;
  --text-gray-dark-color: @text-color-secondary;
}
