.profile-page {
	.profile-photo {
		text-align: center;
		margin: 1rem auto 3rem;
	}

	img {
		width: 10rem;
    height: 10rem;
    object-fit: cover;
    border-radius: 50%;
	}
	.responsive-layout {
		display: flex;

	}

}

@media screen and (max-width: 25rem) {

	.responsive-layout {
		display: flex;
		flex-direction: column;
		.responsive-input {
			width: 15rem !important;
		}
	}
}