.auth-page {
  max-width: 37.5rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 2rem 6.5rem;
  border-radius: 0.5rem;
  background-color: #ffffff;

  img {
    width: 12rem;
    margin: auto;
  }

  h1 {
    color: #111827;
    font-weight: 600;
    font-size: 1.5rem;
    text-align: center;
    line-height: 1.3;
    margin: 2rem auto;
  }

  .submit-btn {
    background-color: #1c64f2;
    color: #ffffff;
    font-weight: 500;
    padding: 0 5rem;
    width: 100%;
    font-size: 0.875rem;
    margin-bottom: 2rem;
  }

  a {
    font-weight: 500;
  }
}


@media (max-width: 48rem) {
  .auth-page {
    box-shadow: none;
    margin-top: 5rem;
    padding: 2rem 1rem;

    h1 {
      font-size: 2rem;
      line-height: 1.5;
      margin: auto;
    }

    h2 {
      font-size: 1.5rem;
    }

    &.signup {
      h3 {
        font-size: 1.375rem;
      }
    }
  }
}