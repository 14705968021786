.not-found-page {
  background: #ffffff;

  .ant-result-icon {
    margin: 2rem auto 2rem;
  }

  .ant-result-title {
    font-size: 2rem;
  }

  .ant-result-subtitle {
    font-size: 1rem;
    margin: 2rem auto 3rem;
  }
}