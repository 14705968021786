.upgrade-plan-page {
	padding-top: 3rem;

	h1 {
		font-size: 3rem;
		font-weight: 600;
		line-height: 1.3;
		text-align: center;
		margin-bottom: 0.8rem;
	}

	h6 {
		text-align: center;
		font-size: 1rem;
		font-weight: 500;

	}

	.pricing-label {
		color: #1c64f2;
		font-weight: 500;
		margin-bottom: 0;
	}

	.pricing-cards {
		margin-top: 3rem;

		.billig-options {
			text-align: center;
			padding: 1rem 0;
		}

		p {
			margin: 0;
		}

		.ant-card {
			height: 100%;
		}

		.ant-card-body {
	    	display: flex;
			flex-direction: column;

			.btn {
				margin-top: 2rem;
				width: 100%;
			}
		}

		h2 {
			font-weight: bold;
		}

		.popular-label {
			color: #1c64f2;
		    font-weight: 500;
		    border-radius: 2rem;
		    background-color: #d6e4ff;
		    padding: 0.2rem 0.8rem;
		}

		.ant-statistic-content-prefix, .ant-statistic-content-value {
			font-size: 2rem;
			font-weight: 600;
		}

		.ant-statistic-content-suffix {
			font-size: 1rem;
		}

		ul {
			list-style: none;
			margin: 0;

			li {
				line-height: 2.6;
	    		display: flex;
    			align-items: baseline;
			}

			.anticon {
			    margin-right: 0.75rem;
	    		color: #1c64f2;
			}
		}
	}
}


@media screen and (min-width: 768px) and (max-width: 1280px) {
	.responsive-card {
		display: grid;
		flex-wrap: wrap;
	  }

	.upgrade-plan-page{
		.pricing-cards {
			.popular-label {
				color: #1c64f2;
				font-weight: 500;
				border-radius: 1rem;
				background-color: #d6e4ff;
				padding: 0.2rem 0.8rem;
			}
			.ant-card-body{
				.btn {
					padding: 0px !important;
				}	
			}
		} 
	}
}