.create-deal-page {
  height: calc(100vh - 4.125rem);
  margin: -2rem;

  .ant-spin-nested-loading, .ant-spin-container {
    height: 100%;
  }

  .form-container {
    overflow-y: scroll;
    height: calc(100vh - 8.125rem);
    padding: 2rem;
  }

  .step-navigator {
    position: absolute;
    bottom: 1.5rem;
    width: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: center;

    .ant-btn {
      background-color: #9ca3af;
      border-color: #9ca3af;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      height: 0.75rem;
      margin: 0 0.25rem;
      padding: 0 1rem;

      &.active {
        padding-top: 6px;
        background-color: #31c48d;
        border-color: #31c48d;
        height: 1rem;
      }
    }
  }
  
  // .next-btn {
  //   position: absolute;
  //   bottom: 1.2rem;
  //   right: 6.5rem;
  //   z-index: 1;
  // }

  // .back-btn {
  //   position: absolute;
  //   bottom: 1.2rem;
  //   left: 6.5rem;
  //   z-index: 1;
  // }


  // @media only screen and (max-width: 1280px) {
  //   .next-btn {
  //     position: absolute;
  //     bottom: 4rem;
  //     right: 10dvh;
  //     z-index: 1;
  //   }
    
  //   .back-btn {
  //     position: absolute;
  //     bottom: 4rem;
  //     left: 5dvh;
  //     z-index: 1;
  //   }
  // }
  // @media only screen and (max-width: 400px) {
  //   .next-btn {
  //     position: absolute;
  //     bottom: 7rem;
  //     right: 13dvh;
  //     z-index: 1;
  //   }
    
  //   .back-btn {
  //     position: absolute;
  //     bottom: 4rem;
  //     left: 10dvh;
  //     z-index: 1;
  //   }
  // }

  .upload-file {
    cursor: pointer;
  }

  .form-label {
    font-weight: 500;
  }

  .hidden-items {
    display: none;
  }

  .ant-input-number-affix-wrapper-readonly, .ant-input-number-readonly {
    background-color: #f8f8f8;
  }
}