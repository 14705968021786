.tooltip-popover {
  width: auto;
  max-width: 300px;
}

.tooltip-info {
  max-height: 500px; /* Adjust the height as needed */
  overflow-y: auto;
}

//   .tooltip-info-section {
//     margin-bottom: 3px;
//   }

.tooltip-info-grid {
  display: flex;
  flex-direction: column;
}

.tooltip-answer {
  font-size: 14px;
}

.info-icon {
  font-size: 14px;
  color: #1d22299c;
  padding-bottom: 10px;
  padding:0px 3px;
}

.tooltip-trigger {
  display: flex;
  align-items: center;
  justify-content: center;
  width: max-content;
  height: max-content;
  cursor: pointer;
  gap: 4px;
}

@media screen and (max-width: 48.1rem) {
  .tooltip-popover {
    width: auto;
    max-width: 200px;
  }

  .tooltip-info {
    max-height: 300px; 
    overflow-y: auto;
    
  }
}
