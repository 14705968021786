/* style.less */

.faq-container {
    max-width: auto;
    margin: auto;
  }
  
  .faq-heading {
    text-align: center;
    font-size: 36px;
    margin-bottom: 30px;
    color: #333;
  }
  
  .faq-accordion {
    background-color: #f7f7f7;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .faq-panel {
    background-color: transparent;
    border: none;
    border-radius: 0;
    margin-bottom: 10px;
    box-shadow: none;
  }
  
  .faq-list {
    list-style: none;
    padding: 0;
  }
  
  .faq-item {
    background-color: #fff;
    border-radius: 10px;
    padding: 20px;
    margin-bottom: 10px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
  }
  
  .faq-item:hover {
    // transform: translateY(-5px);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  }
  
  .faq-question {
    font-size: 20px;
    color: #333;
    margin-bottom: 10px;
  }
  
  .faq-answer {
    font-size: 16px;
    color: #555;
  }
  
  .faq-icon {
    margin-right: 10px;
  }
  