.overview-page {
  .property-actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 3rem;

    .ant-input-affix-wrapper {
      width: auto;
    }
  }

  .statistic-charts {
    margin-bottom: 1rem;
  }

  .statistic-items {
    margin-bottom: 2rem;
    .anticon {
      color: #1c64f2;
      font-size: 1rem;
    }
  }

  .property-item {
    height: 100%;
    margin-bottom: 1rem;

    h2 {
      margin-bottom: 1rem;
    }

    img {
      width: 100%;
    }

    .ant-statistic {

      .ant-statistic-title {
        font-weight: 600;
        color: #111827;
        background: #f3f4f6;
        padding: 0.4rem 0.5rem;
        margin: 0.4rem -0.5rem;
        border-radius: 0.25rem;
      }
      
      .ant-statistic-content {
        font-size: 0.875rem;
        display: flex;
      }
    }
  }
}
@media screen and (max-width: 25rem) {
  .property-actions {
    flex-direction: column;
    gap:1rem;

  }
}