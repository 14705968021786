.intro-content {
  text-align: center;

  .intro-header {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
    color: #1890ff; /* Header color adjusted */
  }

  .intro-steps {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px; /* Added gap between steps */
  }

  .step {
    display: flex;
    align-items: center;
  }

  .dot {
    width: 60px;
    height: 60px;
    background-color: #1890ff;
    color: white;
    font-size: 24px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  }

  .line {
    height: 3px;
    width: 60%; /* Adjusted width */
    background-color: #1890ff;
  }

  .step-text {
    margin-left: 20px;
    font-size: 18px;
    color: #333; /* Adjusted text color */
    max-width: 300px; /* Added max-width */
    text-align: left;
  }

  .intro-footer {
    margin-top: 30px;
  }

  .ant-btn-primary {
    width: 220px;
  }

  .ant-btn-primary:hover,
  .ant-btn-primary:focus {
    background-color: #40a9ff; /* Adjusted hover color */
    border-color: #40a9ff; /* Adjusted hover border color */
  }

  /* Adjust overlay opacity */
  .ant-modal-mask {
    background-color: #000000aa; /* Darker overlay */
  }
}

.transition-content {
  text-align: center;
  .transition-header {
    font-size: 16px;

    margin-bottom: 20px;
    color: #2e2f30;
  }
  .transition-steps {
    display: flex;
    flex-direction: column;
    align-items: start;
    padding: 5px 30px;
    gap: 30px; /* Added gap between steps */
    .steps {
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      text-align: start;
      gap: 5rem;
      // width: 100%;
    }
  }
  .ant-btn-primary {
    width: 200px;
  }

  .ant-btn-primary:hover,
  .ant-btn-primary:focus {
    background-color: #40a9ff; /* Adjusted hover color */
    border-color: #40a9ff; /* Adjusted hover border color */
  }
}

.rent-unit-mix-container {
  padding: 20px;
  margin: 20px;
  border-radius: 8px;
}

.question-section {
  margin-bottom: 20px;
}

.question {
  font-size: 14px;
  
}
.question-btn{
  margin: 10px;
  display: flex;
  align-items: center;
}
@media only screen and (max-width: 768px) {
  .question-section {
    margin-bottom: 10px;
  }

  .question {
    font-size: 16px;
  }
}

.income-and-expenses-container{

  .header-text{
    font-size: 16px;
    font-weight: 500;
  }
  .text-label{
    border:10px solid black;
  }
}






.card-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 20px;
}

.card {
  width: 100%;
}

.card-caption {
  margin-bottom: 16px;
  font-style: italic;
  font-size: 20px;
  color: #666;
}


.next-btn {
  position: absolute;
  bottom: 0.2rem;
  right: 6.5rem;
  z-index: 2;
 
}



.back-btn {
  position: absolute;
  bottom: 0.2rem;
  left: 6.5rem;
  z-index: 2;
 
}

@media only screen and (max-width: 1280px) {
  .next-btn {
    position: absolute;
    // right: 10dvh;
    z-index: 1;
  }
  
  .back-btn {
    position: absolute;
    // left: 5dvh;
    z-index: 1;
  }
}
@media only screen and (max-width: 540px) {
  .next-btn {
    position: absolute;
    
    right: 40%;
    z-index: 1;
  }
  
  .back-btn {
    position: absolute;
    bottom: 3rem;
    left: 30%;
    z-index: 1;
  }
}