.market-research-page {
  .property-actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 3rem;

    .ant-input-affix-wrapper {
      width: auto;
    }
  }

  .rent-cast-board {
  }

  .summary-card {
    height: 100%;

    .ant-card-body {
      display: flex;
      flex-direction: column;
    }

    p {
      margin: 0;
    }

    .blue-label {
      background-color: #ebfafb;
      color: #0ec3d6;
      font-weight: bold;
      border-radius: 2rem;
      padding: 0.3rem 0.8rem;
      margin: auto;
    }

    .heading-1 {
      font-weight: bold;
      margin: 1rem auto;
      
      .ant-statistic-content  {
        font-size: 2rem;
      }
    }

    .heading-2 {
      font-weight: 600;
    }

    .progress-bar {
      width: 100%;
      height: 0.8rem;
      background: linear-gradient(to right, #03c5dd, #3481fd);
      border-radius: 0.5rem;
      margin: 0.4rem 0;
    }
  }

  .submit-btn {
    float: right;
  }

  .ant-statistic {
    .ant-statistic-title {
      font-weight: 600;
      color: #111827;
      background: #f3f4f6;
      padding: 0.4rem 0.5rem;
      margin: 0.4rem -0.5rem;
      border-radius: 0.25rem;
    }
    
    .ant-statistic-content {
      font-size: 0.875rem;
      display: flex;
    }
  }

  .score-icon {
    width: 4rem;
    margin-bottom: 1rem;
  }

  .crime-card {
    .ant-card-body {
      height: 40rem;
    }
  }
}