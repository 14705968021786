.underwriting-page {
  // .ant-tabs-nav-list {
  //   background: #BDFBE9 !important; 

  //   .ant-tabs-tab-active {
  //     background-color: #0ea076 !important;
  //     border-radius: 0.5rem;
  //     color: black;
  //   }
  // }
  .responsive-grid
  {
    display: flex;
    flex-direction: row;
  }
  .financial-responsive-space{
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
  }
  .tab-content {
    .ant-card {
      overflow-x: auto;

      img {
        width: 100%;
        max-height: 16rem;
        margin-bottom: 1rem;
        border-radius: 0.5rem;
        object-fit: cover;
      }

      .ant-statistic {
        .ant-statistic-title {
          font-weight: 600;
          color: #111827;
          background: #f3f4f6;
          padding: 0.4rem 0.5rem;
          margin: 0.4rem -0.5rem;
          border-radius: 0.25rem;
        }
        
        .ant-statistic-content {
          font-size: 0.875rem;
          display: flex;
        }
      }
    }

    &.summary-tab, &.property-details-tab, &.financing-tab {
      .ant-card {
        height: 60vh;
        overflow-y: scroll;
      }
    }

    .info-field {
      .ant-input-number-affix-wrapper {
        width: 100%;
        max-width: 8rem;
      }
      
      > .ant-input-number {
        width: 100%;
        max-width: 8rem;
      }
      .ant-select {
        min-width: 8rem;
      }
      p {
        font-weight: 600;
        color: #111827;
        background: #f3f4f6;
        padding: 0.4rem 0.5rem;
        margin: 0.4rem -0.5rem;
        border-radius: 0.25rem;
      }
    }
  }
  .financials-table-select {
    min-width: 15rem;
  }

  .ant-table-row {
    &:first-child, &:last-child, &:nth-last-child(-n + 2) {
      .ant-table-row-expand-icon {
        display: none;
      }
    }
  }

  .ant-table-expanded-row > td {
    padding: 0;

    .ant-spin-nested-loading {
      margin: 1rem auto;
    }

    .ant-table {
      margin-left: 0 !important;

      .ant-table-row-expand-icon {
        display: none;
      }
    }
  }
  
}
@media  (min-width:768px) and (max-width:1280px) {
  .underwriting-page{
    .responsive-grid {
      flex-direction: column;
    }

    .financial-responsive-space{
      justify-content: center;
    }

    .tab-content {
      .ant-col{
        display: flex;
        flex-wrap: wrap;
      }
      .ant-card {
        img {
          width: 100%;
          max-height: 16rem;
          margin-bottom: 1rem;
          border-radius: 0.5rem;
          object-fit: cover;
        }
  
        .ant-statistic {
          width: 10rem;
          .ant-statistic-title {
            font-weight: 600;
            color: #111827;
            background: #f3f4f6;
            padding: 0.4rem 0.5rem;
            margin: 0.4rem -0.5rem;
            border-radius: 0.25rem;
          }
          
          .ant-statistic-content {
            font-size: 0.875rem;
            display: flex;
          }
        }
      }
  
      &.summary-tab, &.property-details-tab, &.financing-tab {
        .ant-card {
          height: 60vh;
          overflow-y: scroll;
        }
      }
  
      .info-field {
        .ant-input-number-affix-wrapper {
          width: 100%;
          max-width: 8rem;
        }
        
        > .ant-input-number {
          width: 100%;
          max-width: 8rem;
        }
        .ant-select {
          min-width: 8rem;
        }
        p {
          font-weight: 600;
          color: #111827;
          background: #f3f4f6;
          padding: 0.4rem 0.5rem;
          margin: 0.4rem -0.5rem;
          border-radius: 0.25rem;
        }
      }
    }
  }
}
