.mapview-container {
	width: 100%;
	height: 100%;
	display: flex;

	.ant-spin-spinning {
		margin: auto;
	}

	.map-container {
		width: 100%;
		height: 100%;
	}
}